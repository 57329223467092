
import dialogShell from '@/components/common/dialog-shell/index.vue';
import { defineComponent, PropType } from 'vue';
import { MessageForm } from './all.type';

export default defineComponent({
    components: {
        dialogShell
    },
    props: {
        initForm: {
            type: Object as PropType<MessageForm>,
            required: true
        }
    }
});
